import {
  Design,
  DesignQuerySettings,
  FetchExtraSnapshotsResponse,
  GetLibraryDesignsResponse,
} from './Types'
import Moment from 'moment'
import httpClient from '../httpClient'
import { exportToCsv } from '../../helpers/Files'
import currencyJS from 'currency.js'

const DesignService = {
  sendDesignCustomization: function (formData, cb) {
    $.ajax({
      type: 'POST',
      url: '/design_customization_request',
      beforeSend: $.rails.CSRFProtection,
      data: formData,
      success: success => {
        cb(success)
      },
      error: () => {
        cb(null)
      },
    })
  },

  updateOneDesign: function (design: Design, cb) {
    $.ajax({
      type: 'PUT',
      url: `/designs/${design.id}`,
      beforeSend: $.rails.CSRFProtection,
      data: {
        payload: JSON.stringify(design),
        intent: 'update',
      },
      success: function (design) {
        cb(design)
      },
      error: function (xhr, _, errorThrown) {
        toastr.error(xhr.responseJSON?.message || errorThrown)
      },
    })
    return
  },

  requestDesignSharingLink: function (pDesignId, pPriceViewable, cb) {
    $.ajax({
      url: `/designs/${pDesignId}/generate_viewable_link?price_viewable=${pPriceViewable}`,
      type: 'GET',
      beforeSend: $.rails.CSRFProtection,
      success: function (res) {
        cb(res)
      },
      error: function () {
        toastr.error('Link generation failed.', undefined, { timeOut: 3000 })
      },
    })
  },

  fetchOneDesign: function (pDesignId, cb) {
    $.ajax({
      type: 'GET',
      url: `/designs/${pDesignId}/fetch_design`,
      beforeSend: $.rails.CSRFProtection,
      success: function (design) {
        cb(design)
      },
      error: function (err) {
        cb(null, err)
      },
    })
  },

  saveOneDesign: function (design, cb: (design: Design | null, err) => void) {
    $.ajax({
      type: 'POST',
      url: '/designs/',
      beforeSend: $.rails.CSRFProtection,
      data: { payload: JSON.stringify(design) },
      success: function (design) {
        cb(design, null)
      },
      error: function (err) {
        cb(null, err)
      },
    })
    return
  },

  cloneOneDesign: function (design, cb) {
    $.ajax({
      type: 'POST',
      url: `/designs/${design.id}/clone_design`,
      beforeSend: $.rails.CSRFProtection,
      data: { payload: JSON.stringify(design) },
      success: function (res) {
        GoogleAnalyticsHelper.VentionGAEvent(
          'customized_public_design',
          'click',
          'funnel',
          res.lead_designer_id
        )
        if (undefined != cb && 'function' === typeof cb) {
          cb(res)
        }
      },
      error: function (res) {
        if (res.status === 401) {
          window.location.href = '/users/sign_in'
        }
      },
    })
    return
  },

  fetchDesignList: function (
    ids: ReadonlyArray<number>,
    cb?: (parts: ReadonlyArray<Design>) => void
  ): Promise<ReadonlyArray<Design>> {
    return new Promise(function (resolve, reject) {
      $.ajax({
        type: 'GET',
        url: `/fetch_design_list?ids=${ids}`,
        beforeSend: $.rails.CSRFProtection,
        success: function (designs) {
          if (cb !== undefined) {
            cb(designs)
          }
          resolve(designs)
        },
        error: function (error) {
          reject(error)
        },
      })
    })
  },

  deleteDesigns: function (pDesignIds, callBack) {
    $.ajax({
      url: `/designs?id=${pDesignIds.join(',')}`,
      method: 'DELETE',
      success: function (res) {
        callBack(res)
      },
      error: function (res) {
        callBack(null, res)
      },
    })
  },

  getDesigns: function (querySettings: Partial<DesignQuerySettings>, cb) {
    const category = querySettings.category ? querySettings.category : 'all'
    const sub_category = querySettings.subCategory ? querySettings.subCategory : ''
    const sort_by = querySettings.sortBy ? querySettings.sortBy : 'last_modified'
    const filter_by = querySettings.filter_by ? querySettings.filter_by : 'al'
    const count = querySettings.designsPerPage ? querySettings.designsPerPage : 20
    const page_number = querySettings.pageNumber ? querySettings.pageNumber : 1
    const designer = querySettings.designer
    const query = querySettings.searchTerm ? querySettings.searchTerm : []
    const lifecycle = querySettings.lifecycle

    httpClient
      .get('/public_api/designs', {
        params: {
          page_number: page_number,
          category: category,
          sub_category: sub_category,
          sort_by: sort_by,
          filter_by: filter_by,
          count: count,
          designer: designer,
          query: query,
          lifecycle: lifecycle,
        },
      })
      .then(res => {
        if (cb) {
          cb(res.data, null)
        }
      })
      .catch(error => {
        if (cb) {
          cb(null, error)
        }
      })
  },

  // use simpler/optimal version getDesigns instead if possible
  fetchDesigns: function (querySettings: DesignQuerySettings, cb) {
    const category = querySettings.category ? querySettings.category : 'all'
    const design_author = querySettings.designAuthor ? querySettings.designAuthor : ''
    const sub_category = querySettings.subCategory ? querySettings.subCategory : ''
    const sort_by = querySettings.sortBy ? querySettings.sortBy : 'last_modified'
    const filter_by = querySettings.filter_by ? querySettings.filter_by : 'al'
    const count = querySettings.designsPerPage ? querySettings.designsPerPage : 20
    const page_number = querySettings.pageNumber ? querySettings.pageNumber : 1
    const component = querySettings.component ? querySettings.component : ''
    const filter_by_tag = querySettings.tagList ? querySettings.tagList : []
    const designer = querySettings.designer
    const query = querySettings.searchTerm ? querySettings.searchTerm : []
    const tag_match_type = querySettings.tag_match_type
    const get_tags = querySettings.get_tags
    const get_partners = querySettings.get_partners
    const is_design_request = querySettings.is_design_request
    const lifecycle = querySettings.lifecycle
    const price_range = querySettings.price_range
    const assembly_time_range = querySettings.assembly_time_range
    const min_price = querySettings.min_price
    const max_price = querySettings.max_price
    const min_assembly_time = querySettings.min_assembly_time
    const max_assembly_time = querySettings.max_assembly_time
    const filter_by_partner = querySettings.filter_by_partner
    const design_ids = querySettings.id

    httpClient
      .get('/fetch_designs', {
        params: {
          page_number: page_number,
          category: category,
          design_author: design_author,
          sub_category: sub_category,
          sort_by: sort_by,
          filter_by: filter_by,
          count: count,
          component: component,
          filter_by_tag: filter_by_tag,
          designer: designer,
          query: query,
          tag_match_type: tag_match_type,
          get_tags: get_tags,
          get_partners: get_partners,
          is_design_request: is_design_request,
          lifecycle: lifecycle,
          price_range: price_range,
          assembly_time_range: assembly_time_range,
          min_price: min_price,
          max_price: max_price,
          min_assembly_time: min_assembly_time,
          max_assembly_time: max_assembly_time,
          filter_by_partner: filter_by_partner,
          design_ids: design_ids,
        },
      })
      .then(res => {
        if (cb) {
          cb(res.data, null)
        }
      })
      .catch(error => {
        if (cb) {
          cb(null, error)
        }
      })
  },

  // TODO: when this has proven to withstand the wrath of time, delete the above method in favor of this one
  fetchLibraryDesigns: async function (querySettings: DesignQuerySettings) {
    try {
      const created_at_greater_than =
        querySettings.category === 'NEW' ? Moment().subtract(30, 'days').valueOf() : null

      const response = await httpClient.get<GetLibraryDesignsResponse>('/library_designs', {
        params: {
          page_number: querySettings.pageNumber ?? 1,
          count: querySettings.designsPerPage ?? 20,
          limit: querySettings.limit,
          category: querySettings.category ?? 'all',
          sub_category: querySettings.subCategory ?? '',
          sort_by: querySettings.sortBy ?? 'last_modified',
          filter_by: querySettings.filter_by ?? 'al',
          component: querySettings.component ?? '',
          is_design_request: querySettings.is_design_request,
          filter_by_tag: querySettings.tagList ?? [],
          tag_match_type: querySettings.tag_match_type,
          get_tags: querySettings.get_tags ?? null,
          designer: querySettings.designer,
          query: querySettings.searchTerm ?? [],
          lifecycle: querySettings.lifecycle,
          filter_by_partner: querySettings.filter_by_partner ?? [],
          price_range: querySettings.price_range?.toString(),
          min_price: querySettings.min_price,
          max_price: querySettings.max_price,
          assembly_time_range: querySettings.assembly_time_range?.toString(),
          min_assembly_time: querySettings.min_assembly_time,
          max_assembly_time: querySettings.max_assembly_time,
          design_author: querySettings.designAuthor,
          created_at_greater_than,
        },
      })

      return response.data
    } catch (error) {
      console.error(error)
      toastr.error('Could not retrieve designs from server.')
    }
  },

  designLifecycleCounts: function (cb) {
    $.ajax({
      type: 'GET',
      url: '/design_lifecycle_counts',
      beforeSend: $.rails.CSRFProtection,
      success: function (res) {
        cb(res)
      },
      error: function (res) {
        cb(null, res)
      },
    })
  },
  fetchExtraSnapshots: async function (
    designId: number
  ): Promise<ReadonlyArray<FetchExtraSnapshotsResponse> | undefined> {
    try {
      const response = await httpClient.get<ReadonlyArray<FetchExtraSnapshotsResponse>>(
        `/designs/${designId}/extra_snapshots`
      )

      return response.data
    } catch (error) {
      console.error(error)
    }
  },
  downloadBom: async function (designId: number, completedCallback?: () => void) {
    DesignService.fetchOneDesign(designId, design => {
      const fileName = `${design.design_number}_v${design.version}.csv`
      design.parts = [...design.new_parts, ...design.partner_parts]
      const dataArray = design.parts.map(part => {
        let unitPrice = part.unit_price >= 0 ? part.unit_price : part.supplier_price_usd,
          name = part.name,
          partNumber = part.part_number
        if (Object.prototype.hasOwnProperty.call(part, 'instance_parameters')) {
          unitPrice = part.instance_parameters.custom_unit_price
            ? part.instance_parameters.custom_unit_price
            : unitPrice
          name = part.instance_parameters.imported_part_name
            ? part.instance_parameters.imported_part_name
            : name
          partNumber = part.instance_parameters.imported_part_number
            ? part.instance_parameters.imported_part_number
            : partNumber
        }

        // custom parts like panels, that have a quantity of 1 but have a computed quantity,
        // also include the price of panel holes, which should be accounted for in `row_total`.
        if (part.computed_unit_quantity) {
          unitPrice =
            part.quantity === 1
              ? part.row_total
              : currencyJS(unitPrice).multiply(part.computed_unit_quantity)
        }

        return [
          partNumber,
          name,
          part.status,
          unitPrice,
          part.quantity,
          part.row_total,
          part.currency_code,
        ]
      })
      dataArray.unshift([
        'Part number',
        'Item description',
        'Status',
        'Unit price',
        'Quantity',
        'Subtotal',
        'Currency code',
      ])
      exportToCsv(fileName, dataArray)

      if (completedCallback) {
        completedCallback()
      }
    })
  },
}

export default DesignService
