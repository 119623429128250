import httpClient from '../httpClient'
import { CartCountResponse } from './Types'

export function updateCartDot(): void {
  httpClient
    .get<CartCountResponse>('/public_api/carts/count')
    .then(response => {
      const quantity = response.data.count
      let cartBadge = $('.utility-cart-badge')
      if (!cartBadge.length) {
        cartBadge = $('.cart_quantity_icon')
      }

      if (!cartBadge) return

      if (quantity == 0) {
        cartBadge.addClass('hidden')
      } else {
        cartBadge.removeClass('hidden')
        cartBadge.text(quantity)
      }
    })
    .catch(error => {
      console.error(error)
    })
}

export function quickAddToCart(
  event,
  url_fetch,
  type,
  cb?: (url?: string | JQuery.jqXHR<any>) => void
): void {
  GoogleAnalyticsHelper.VentionGAEvent('add_to_cart', 'click', 'shopping Cart')

  if (event) {
    event.preventDefault()
  }

  $.ajax({
    type: 'POST',
    url: url_fetch,
    success: function (res) {
      if (res) {
        toastr.success(
          `${type} added successfully!<br><a style='text-decoration:underline;' href='/checkout/cart'>Go to your Cart</a>.`,
          '',
          { preventDuplicates: false }
        )
        updateCartDot()
      } else {
        toastr.info('There were some errors happened...', `${type} was not added!`)
      }
      if (cb != undefined) {
        cb(res)
      }
    },
    error: function (res) {
      if (res.status == 404) {
        toastr.info('Error')
      }
      if (res.status == 400) {
        toastr.error(res.responseJSON.message)
      }
      if (cb != undefined) {
        cb(res)
      }
    },
  })
}

export const addOnePartToCart = (
  partId: string, 
  callback: (res: unknown) => void
) => quickAddToCart(
  null,
  `/parts/${partId}/buy?quantity=${1}`,
  "Part",
  (response) => {
    callback(response)
  }
)